import '../registration_form/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../i18n';
import { FC } from 'react';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';

const eventPolyfill = dynamic(() => import('vanilla-autofill-event/src/autofill-event'), { ssr: false });

const XtraRegformApp: FC<AppProps & { err }> = ({
  Component, pageProps, err, ...etc
}) => (
  <Component {...pageProps} err={err} />
);

export default XtraRegformApp;
